li {
  display: flex;
}

.navbar {
  position: relative;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  background-color: rgba(186, 225, 219, 0.75);
  align-items: center;
  padding: .5rem 1rem;
  border-radius: 1rem;
  box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.ml-auto, .mx-auto {
  margin-left: auto!important;
}

.nav-link {
  display: block;
  color: white;
  padding: .5rem 1rem;
  text-decoration: none;
}

.card-container.card {
  max-width: 250px !important;
  padding: 40px 40px;
}

.card {
  background-color:  rgba(96, 135, 134, 0.75);
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 1rem;
  box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
  /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}
.logo-img {
  padding-right: 10px;
  height: 3rem;
  vertical-align: middle;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-group {
  padding: 0.3rem;
}

.form-control {
  width: 100%;
}

label {
  color: white;
}

button {
  width: 100%;
  color: #fff;
  background-color: 	hsla(211, 46%, 42%, 1);
  border-color: hsla(123, 59%, 38%, 0.5);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .3rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}