.cluster-container {
	height: 50px;
	width: 100%;
    background: rgb(53,53,53);
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
    grid-column: span 1;
    grid-row: span 1;
}

.lampCard-container {
	height: 200px;
	width: 150px;
    background: rgb(53,53,53);
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
    grid-column: span 1;
    grid-row: span 1;
}

.lampCard-display-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50%;
}

.lampCard-display {
	display: flex;
	border-radius: 50%;
	color: #ffffff;
    background-color: #186018;
	height: 85px;
	width: 85px;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	border: 3px #ffffff solid;
	transition: background 0.5s;
}

.lampCard-display-yellow {
	display: flex;
	border-radius: 50%;
	color: #000000;
    background-color: #ffff00;
	height: 85px;
	width: 85px;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	border: 3px #ffffff solid;
	transition: background 0.5s;
}

.lampCard-display-red {
	display: flex;
	border-radius: 50%;
	color: #ffffff;
    background-color: #ff0000;
	height: 85px;
	width: 85px;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	border: 3px #ffffff solid;
	transition: background 0.5s;
}


.lampCard-title-container {
    display: flex;
    margin-top: 5px;
	justify-content: space-evenly;
    align-items: center;
}

.lampCard-title {
	display: flex;
	color: #ffffff;
	height: 30px;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}

.lampCard-list-container {
    display: float;
	justify-content: space-evenly;
    align-items: center;
}

.lampCard-list {
	display: float;
	color: #ffffff;
    height: 20px;
    width: 100%;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}
