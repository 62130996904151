.Container {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: "cover";
  transform: translate(-50%, -50%);
  z-index: -1;
  background-color: rgb(61, 87, 86);
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
    width: 100%;
    height: 100%;
  }
  