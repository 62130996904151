.grid_container {
    position: relative;
    top: 0.3rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    gap: 0.3rem;
    /* align-items: stretch; */
    /* grid-auto-rows: 100px; */
    grid-auto-flow: dense;
    margin: 0;
    z-index: 0;
    background-color: inherit;
    color: white;
    /* box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5); */
}
  
.viewcard11 {
    background-color: rgba(96, 135, 134, 0.75);;
    border-radius: 12px;
    padding: 10px;
    width: auto;
    /* max-width: 100%; */
    grid-column: span 1;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
}
  
.viewcard12 {
    background-color: rgba(96, 135, 134, 0.75);
    border-radius: 12px;
    padding: 10px;
    width: auto;
    grid-column: span 1;
    grid-row: span 2;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
}
  
.viewcard21 {
    background-color: rgba(96, 135, 134, 0.75);
    border-radius: 12px;
    padding: 10px;
    width: auto;
    grid-column: span 2;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
}
  
.viewcard31 {
    background-color: rgba(96, 135, 134, 0.75);
    border-radius: 12px;
    padding: 10px;
    width: auto;
    grid-column: span 3;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
}
  
.viewcard41 {
    background-color: rgba(96, 135, 134, 0.75);
    border-radius: 12px;
    padding: 10px;
    width: auto;
    grid-column: span 4;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
}
  
.viewcard21center {
    background-color: rgba(96, 135, 134, 0.75);
    justify-self: center;
    border-radius: 12px;
    padding: 10px;
    width: auto;
    grid-column: span 4;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
}
  
.viewcard22 {
    background-color: rgba(96, 135, 134, 0.75);
    border-radius: 12px;
    padding: 10px;
    width: auto;
    grid-column: span 2;
    grid-row: span 2;
    box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5);
}

[data-tooltip] {
    position: relative;
}
  
[data-tooltip]::before,
[data-tooltip]::after {
    --tooltip-color: rgb(96, 135, 134);
    --arrow-size: .5rem;
  
    position: absolute;
    left: 50%;
    transform: translate(-50%, var(--translate-y));
}
  
[data-tooltip]:hover::before {
    --translate-y: calc(-100% - var(--arrow-size));
  
    content: attr(data-tooltip);
    background-color: var(--tooltip-color);
    color: white;
    padding: .5em;
    border-radius: .3em;
    width: max-content;
    max-width: 100%;
    text-align: center;
    font-size: 12px;
}
  
[data-tooltip]:hover::after {
    --translate-y: calc(-1 * var(--arrow-size));
  
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
}

.lamp-group-container {
    display:flex;
    justify-content:center;
    position: relative;
    top: 0.3rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.3rem;
    /* align-items: stretch; */
    /* grid-auto-rows: 100px; */
    grid-auto-flow: dense;
    margin: 0;
    z-index: 0;
    /* background-color: hsla(185, 100%, 25%, 0.75); */
    color: white;
    /* box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.5); */
}

.cluster-marker {
    color: #fff;
    background: #19c822;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.crime-marker {
    background: none;
    border: none;
}
  
.crime-marker img {
    width: 25px;
}

.crime-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
